import { VisibilityStates } from "../types/VisibilityStates";

export const getDefaultVisibilityStates = (): VisibilityStates => ({
  isDotCountTypeVisible: false,
  isMinDotsVisible: false,
  isMaxDotsVisible: false,
  isTypeSelectVisible: false,
  isItemCountVisible: false,
  isCollectionItemCountVisible: false,
  isDotColorVisible: false,
  isDotShapeVisible: false,
  isGenerateRandomSequenceButtonVisible: false,
  isFileUploadVisible: false,
  isClearButtonVisible: false,
  isGeneratedSequencePreviewVisible: false,
  isSearchInputVisible: false,
  isSortSelectVisible: false,
  isCollectionsGridVisible: false,
  isPreviewButtonVisible: false,
  isSaveButtonVisible: false,
  isItemPreviewVisible: false,
  isMathProblemVisible: false,
  isDotButtonVisible: false,
  isImageUploadVisible: false,
  isNameInputVisible: false,
  isAddNameButtonVisible: false,
  isSpinButtonVisible: false,
  isNamesListVisible: false,
  isCollectionNameVisible: false,
  isCategorySelectVisible: false,
  isStageSelectVisible: false,
  isPublicCheckboxVisible: false,
  isSubmitButtonVisible: false,
  isCollectionCardVisible: false,
  isStartCollectionButtonVisible: false,
  isEditCollectionButtonVisible: false,
  isDeleteCollectionButtonVisible: false,
  isSessionSettingsModalVisible: false,
  isEditCollectionModalVisible: false,
  isDuplicateCollectionModalVisible: false,
  isCollectionPreviewModalVisible: false,
  isNextButtonVisible: false,
});
